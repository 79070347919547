export default function initializeSelect2() {
  jQuery(document).ready(function ($) {
    const popupResellers = $(".popup__reseller");
    const productLink = $("#product-link");
    const popup = $(".popup");
    const closePopup = $(".popup__close");
    const select = $("#country").select2({
      minimumResultsForSearch: -1,
    });

    closePopup.on("click", function () {
      popup.removeClass("popup--active");
      popupResellers.each(function () {
        $(this).removeClass("popup__reseller--active");
      });
    });

    $(productLink).on("click", function () {
      const country = $(select).select2("data")[0].text;
      const countryNoSpace = country.replace(/\s+/g, " ").trim();
      const popupCountry = $('.popup__reseller[data-country="' + countryNoSpace + '"]');

      if (popupCountry.hasClass("popup__reseller--active") && popup.hasClass("popup--active")) {
        popup.removeClass("popup--active");
        popupResellers.each(function () {
          $(this).removeClass("popup__reseller--active");
        });
        return;
      }

      popup.removeClass("popup--active");
      popupResellers.each(function () {
        $(this).removeClass("popup__reseller--active");
      });
      popupCountry.addClass("popup__reseller--active");
      popup.addClass("popup--active");
    });

    $(select).on("select2:select", function (e) {
      popup.removeClass("popup--active");
      popupResellers.each(function () {
        $(this).removeClass("popup__reseller--active");
      });
    });
  });
}
