export default function initializeAccordion(el) {
  el &&
    el.addEventListener("click", function (event) {
      if (event.target.closest(".faq__item")) {
        const item = event.target.closest(".faq__item");
        const itemOpenText = item?.querySelector(".faq__item-icon-text").dataset.openText;
        const itemCloseText = item?.querySelector(".faq__item-icon-text").dataset.closeText;

        const allItems = el.querySelectorAll(".faq__item");
        allItems.forEach(function (el) {
          if (el !== item && el.classList.contains("faq__item--active")) {
            el.classList.remove("faq__item--active");
            el.querySelector(".faq__item-icon-text").textContent = itemOpenText;
          }
        });

        if (!item.classList.contains("faq__item--active")) {
          item.classList.add("faq__item--active");
          item.querySelector(".faq__item-icon-text").textContent = itemCloseText;
          return;
        }

        item.querySelector(".faq__item-icon-text").textContent = itemOpenText;
        item.classList.remove("faq__item--active");
      }
    });
}
