export default function initializeStreamSwitcher() {
  const buttons = document.querySelectorAll(".stream__host-name-wrapper");
  const streamEmbed = document.querySelector(".stream__embed");
  const initStream = streamEmbed?.dataset.initStream;
  const streamEmbedPlaceHolder = document.querySelector(".stream__embed-placeholder");

  streamEmbed &&
    streamEmbed.addEventListener("click", function () {
      streamEmbedPlaceHolder.remove();
      const newIframe = `<iframe
    src="https://player.twitch.tv/?channel=${initStream}&parent=wintercup.online"
    frameborder="0" allowfullscreen="true" scrolling="no" autoplay="false" loading="lazy"></iframe>`;
      streamEmbed.innerHTML = newIframe;
    });

  buttons &&
    buttons.forEach((button) => {
      button.addEventListener("click", function () {
        const currstreamEmbedIframe = document.querySelector(".stream__embed iframe");
        const channelNameData = button.dataset.channelName;
        currstreamEmbedIframe.src = `https://player.twitch.tv/?channel=${channelNameData}&parent=wintercup.online`;
      });
    });
}
