import initializeAccordion from "./accordion/faq.js";
import initializeNav from "./nav/nav.js";
import initializeLanguageSwitcher from "./nav/languageSwitcher.js";
import initializeSelect2 from "./select2/select2.js";
import initializeStreamSwitcher from "./stream/streamSwitcher.js";
import initializeAos from "./aos/aos.js";
import initializeSuccessAlert from "./alert/alert.js";

document.addEventListener("DOMContentLoaded", function () {
  const faqElements = document.querySelector(".faq__container");

  initializeAccordion(faqElements);
  initializeNav();
  initializeLanguageSwitcher();
  initializeSelect2();
  initializeStreamSwitcher();
  initializeAos();
  initializeSuccessAlert();
});
