export default function initializeNav() {
  const headerNavbar = document.querySelector(".header-navbar");
  const hamburger = document.querySelector(".header-navbar__hamburger");
  const hamburgerIcon = document.querySelector(".header-navbar__hamburger-icon");
  const menu = document.querySelector(".header-navbar__menu");
  const menuItems = menu?.querySelectorAll(".menu-item");

  if (window.scrollY > 0) {
    headerNavbar.classList.add("header-navbar--scrolled");
    return;
  }

  window.addEventListener("scroll", () => {
    if (window.scrollY > 0) {
      headerNavbar.classList.add("header-navbar--scrolled");
      return;
    }
    headerNavbar.classList.remove("header-navbar--scrolled");
  });

  menuItems &&
    menuItems.forEach((item) => {
      item.addEventListener("click", () => {
        menu.classList.remove("header-navbar__menu--active");
        hamburgerIcon.classList.remove("animation-rotate");
        hamburgerIcon.src = `${window.location.origin}/app/themes/toornament/src/img/hamburger.svg`;
      });
    });

  hamburger &&
    hamburger.addEventListener("click", function () {
      menu.classList.toggle("header-navbar__menu--active");

      if (menu.classList.contains("header-navbar__menu--active")) {
        hamburgerIcon.classList.add("animation-rotate");
        hamburgerIcon.src = `${window.location.origin}/app/themes/toornament/src/img/close.svg`;
        return;
      }

      hamburgerIcon.classList.remove("animation-rotate");
      hamburgerIcon.src = `${window.location.origin}/app/themes/toornament/src/img/hamburger.svg`;
    });
}
