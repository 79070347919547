export default function initializeSuccessAlert() {
  const alert = document.querySelector(".success-alert");

  if (document.querySelector(".newsletter__right-side .gform_validation_errors")) {
    alert && alert.remove();
  }

  alert &&
    setTimeout(() => {
      alert.remove();
      window.history.replaceState({}, document.title, window.location.pathname);
    }, 3000);
}
