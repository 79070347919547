export default function initializeAos() {
  AOS.init({
    disable: "mobile",
    startEvent: "DOMContentLoaded",
    initClassName: "aos-init",
    animatedClassName: "aos-animate",
    useClassNames: false,
    disableMutationObserver: false,
    debounceDelay: 50,
    throttleDelay: 99,
    offset: 220,
    delay: 0,
    duration: 700,
    easing: "swing",
    once: true,
    mirror: false,
    anchorPlacement: "top-bottom",
  });
}
