export default function initializeLanguageSwitcher() {
  const headerNavbarLanguage = document.querySelectorAll(".header-navbar__language-wrapper");

  headerNavbarLanguage &&
    headerNavbarLanguage.forEach((languageSwitcher) => {
      languageSwitcher.addEventListener("click", () => {
        const langaugeList = languageSwitcher.querySelector(".header-navbar__language-list");
        langaugeList.classList.toggle("header-navbar__language-list--active");
      });
    });
}
